import * as i0 from "@angular/core";
/**
 * This service should be used incase we need to refer window object.
 */
var WindowRefService = /** @class */ (function () {
    function WindowRefService() {
        this.nativeWindow = window;
        this.nativeDocument = document;
    }
    WindowRefService.prototype.isPathExists = function (subPath) {
        if (this.nativeWindow.location.href.indexOf(subPath) !== -1) {
            return true;
        }
        return false;
    };
    WindowRefService.prototype.getQueryStringValue = function (key) {
        var searchParams = this.nativeWindow.location.href.substring(this.nativeWindow.location.href.indexOf('?') + 1, this.nativeWindow.location.href.length);
        var queryString = new URLSearchParams(searchParams);
        return queryString.get(key);
    };
    WindowRefService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowRefService_Factory() { return new WindowRefService(); }, token: WindowRefService, providedIn: "root" });
    return WindowRefService;
}());
export { WindowRefService };
