import * as i0 from "@angular/core";
var ManualHotspotService = /** @class */ (function () {
    function ManualHotspotService() {
        this.shapesComponents = [];
        // do nothing
    }
    ManualHotspotService.prototype.getRectangleComponents = function () {
        return this.shapesComponents;
    };
    ManualHotspotService.prototype.removeSelectedRectangleComponent = function (component) {
        var comps = this.getRectangleComponents();
        var index = comps.indexOf(component, 0);
        if (index > -1) {
            comps.splice(index, 1);
        }
    };
    ManualHotspotService.prototype.removeAllRectangleComponents = function () {
        this.shapesComponents = [];
    };
    ManualHotspotService.prototype.setRectangleComponent = function (component) {
        this.selectedComponent = component;
        this.shapesComponents.push(component);
    };
    ManualHotspotService.prototype.getRectangleComponent = function () {
        return this.selectedComponent;
    };
    ManualHotspotService.prototype.findRectangleComponent = function (name) {
        return this.shapesComponents.find(function (x) { return x.shape.shapeProperties.name === name; });
    };
    ManualHotspotService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManualHotspotService_Factory() { return new ManualHotspotService(); }, token: ManualHotspotService, providedIn: "root" });
    return ManualHotspotService;
}());
export { ManualHotspotService };
