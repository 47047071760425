import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ToasterService } from '@core/toaster.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./toaster.service";
var BaseService = /** @class */ (function () {
    function BaseService(router, toaster) {
        this.router = router;
        this.toaster = toaster;
        this.header = new Headers();
        this.baseUrl = environment.API_URL;
        this.authToken = this.getStoredAuthToken();
        this.refreshToken = this.getStoredRefreshToken();
        this.uuid = this.getStoredUuid();
    }
    BaseService.prototype.setContentHeaders = function () {
        this.header.delete('Content-Type');
        this.header.append('Content-Type', 'application/json');
    };
    BaseService.prototype.getStoredUuid = function () {
        return localStorage.getItem('uuid');
    };
    BaseService.prototype.getStoredAuthToken = function () {
        return localStorage.getItem('access_token');
    };
    BaseService.prototype.getStoredRefreshToken = function () {
        return localStorage.getItem('refresh_token');
    };
    BaseService.prototype.getUuid = function () {
        this.uuid = this.getStoredUuid();
        return this.uuid;
    };
    BaseService.prototype.getAuthToken = function () {
        this.authToken = this.getStoredAuthToken();
        return this.authToken;
    };
    BaseService.prototype.getRefreshToken = function () {
        this.refreshToken = this.getStoredRefreshToken();
        return this.refreshToken;
    };
    BaseService.prototype.setUuid = function (uuid) {
        this.uuid = uuid;
        localStorage.setItem('uuid', uuid);
    };
    BaseService.prototype.setAuthToken = function (token) {
        this.authToken = token;
        localStorage.setItem('access_token', token);
    };
    BaseService.prototype.setRefreshToken = function (token) {
        this.refreshToken = token;
        localStorage.setItem('refresh_token', token);
    };
    BaseService.prototype.clearTokens = function () {
        this.authToken = null;
        this.refreshToken = null;
        this.uuid = null;
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('uuid');
    };
    BaseService.prototype.saveTokens = function (dataObj) {
        var uuid = dataObj.user_id;
        var authToken = dataObj.access_token;
        var refreshToken = dataObj.refresh_token;
        this.setUuid(uuid);
        this.setAuthToken(authToken);
        this.setRefreshToken(refreshToken);
    };
    BaseService.prototype.genericSuccess = function (data) {
        this.toaster.success(data.body.message);
        return data;
    };
    BaseService.prototype.genericError = function (error) {
        var sError = '';
        try {
            sError = JSON.parse(error.error._body).error.message;
        }
        catch (e) {
            sError = 'Something went wrong. Please try again!';
        }
        this.toaster.error(sError);
        return throwError(sError);
    };
    BaseService.prototype.loginErrorHndlr = function (error) {
        var sError;
        if (error.status === 422) {
            sError = 'This email is already taken up';
        }
        else if (error.status === 404) {
            sError = error.error.message;
        }
        else {
            sError = 'Oops! This combination is not valid';
        }
        return throwError(sError);
    };
    BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ToasterService)); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
