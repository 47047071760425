import { RouterModule } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { AuthGuardService as AuthGuard } from '@core/auth-guard.service';
import { AppGuardService as AppGuard } from '@core/app-guard.service';
var ɵ0 = function () { return import("./auth/auth.module.ngfactory").then(function (m) { return m.AuthModuleNgFactory; }); }, ɵ1 = function () { return import("./main/main.module.ngfactory").then(function (m) { return m.MainModuleNgFactory; }); };
var appRoutes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'sign-in', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'auth', canActivate: [AuthGuard], loadChildren: ɵ0 },
    { path: 'app', canActivate: [AppGuard], loadChildren: ɵ1 },
    { path: '**', component: Page404Component }
];
export var AppRoutingModule = RouterModule.forRoot(appRoutes, { useHash: true });
export { ɵ0, ɵ1 };
