import * as tslib_1 from "tslib";
/*
 * The MousePosition object
 */
var MousePosition = /** @class */ (function () {
    function MousePosition() {
        this.x = 0;
        this.y = 0;
    }
    return MousePosition;
}());
export { MousePosition };
/*
 * The ShapeProperties object
 */
var ShapeProperties = /** @class */ (function () {
    function ShapeProperties() {
        this.fill = true;
        this.fillColor = 'rgba(19, 14, 239, 0.4)';
        this.stroke = true;
        this.strokeColor = 'black';
        this.strokeWidth = 1;
        this.name = 'unknown';
        this.visible = true;
    }
    return ShapeProperties;
}());
export { ShapeProperties };
/*
 * The Shape object=
 */
var Shape = /** @class */ (function () {
    function Shape(name) {
        this.shapeProperties = new ShapeProperties();
        this.originX = this.originY = 0;
        this.shapeProperties.name = name;
    }
    return Shape;
}());
export { Shape };
/*
 * The Rectangle class.
 */
var Rectangle = /** @class */ (function (_super) {
    tslib_1.__extends(Rectangle, _super);
    function Rectangle() {
        var _this = _super.call(this, 'rectangle-' + Rectangle.id++) || this;
        _this.width = _this.height = 0;
        return _this;
    }
    Rectangle.id = 0;
    return Rectangle;
}(Shape));
export { Rectangle };
