import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/base.service';
import { Constants } from '@shared/services/constants.service';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./toaster.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./cookie.service";
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(router, toaster, http, cookie) {
        var _this = _super.call(this, router, toaster) || this;
        _this.router = router;
        _this.toaster = toaster;
        _this.http = http;
        _this.cookie = cookie;
        return _this;
    }
    AuthService.prototype.login = function (userObj) {
        var url = this.baseUrl + Constants.apiEndPoints.signin;
        return this.http.post(url, userObj);
    };
    AuthService.prototype.signup = function (userObj) {
        var url = this.baseUrl + Constants.apiEndPoints.signup;
        return this.http.post(url, userObj);
    };
    AuthService.prototype.mailAuthenticate = function (token) {
        var url = this.baseUrl + Constants.apiEndPoints.authenticate;
        return this.http.post(url, { confirmation_token: token });
    };
    AuthService.prototype.logout = function () {
        var url = this.baseUrl + Constants.apiEndPoints.signout;
        return this.http.delete(url);
    };
    AuthService.prototype.isAuthenticated = function () {
        return (this.getAuthToken()) ? true : false;
    };
    AuthService.prototype.isRegistered = function () {
        var cookieName = 'buildernow_registereduser';
        var result = this.cookie.getCookie(cookieName);
        return (result === null) ? false : true;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ToasterService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.CookieService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}(BaseService));
export { AuthService };
