import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ManualShareService = /** @class */ (function () {
    function ManualShareService() {
        // Observable string sources
        this.removeUnsavedSideKickSource = new Subject();
        this.enableSavedSidekickSource = new Subject();
        this.disableSavedSidekickSource = new Subject();
        this.changeManualHotspotImageSource = new Subject();
        this.changeManualHotspotTypeSource = new Subject();
        this.deleteManualHotSpotSource = new Subject();
        // create observables vars
        this.removeUnsavedSideKick$ = this.removeUnsavedSideKickSource.asObservable();
        this.enableSavedSidekick$ = this.enableSavedSidekickSource.asObservable();
        this.disableSavedSidekick$ = this.disableSavedSidekickSource.asObservable();
        this.changeManualHotspotImage$ = this.changeManualHotspotImageSource.asObservable();
        this.changeManualHotspotType$ = this.changeManualHotspotTypeSource.asObservable();
        this.deleteManualHotSpot$ = this.deleteManualHotSpotSource.asObservable();
    }
    /**
     * @description: method will use to pass data to child
     * component
     */
    ManualShareService.prototype.removeUnsavedSideKickFun = function (sidekickObj) {
        this.removeUnsavedSideKickSource.next(sidekickObj);
    };
    /**
     * @description: Method will use to enable sidekick checkbox
     * @param sidekickImgId SideKick
     */
    ManualShareService.prototype.enableSavedSidekickFun = function (sidekickImgId) {
        this.enableSavedSidekickSource.next(sidekickImgId);
    };
    /**
     * @description: Method will use to disable sidekick checkbox
     * @param sidekickImgId SideKick
     */
    ManualShareService.prototype.disableSavedSidekickFun = function (sidekickImgId) {
        this.disableSavedSidekickSource.next(sidekickImgId);
    };
    ManualShareService.prototype.changeHotspotImageFun = function (sidekickObj) {
        this.changeManualHotspotImageSource.next(sidekickObj);
    };
    ManualShareService.prototype.changeHotspotImageType = function (selectectedCategoryType) {
        this.changeManualHotspotTypeSource.next(selectectedCategoryType);
    };
    ManualShareService.prototype.deleteManualHotSpotFun = function () {
        this.deleteManualHotSpotSource.next();
    };
    ManualShareService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManualShareService_Factory() { return new ManualShareService(); }, token: ManualShareService, providedIn: "root" });
    return ManualShareService;
}());
export { ManualShareService };
