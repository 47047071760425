import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter, finalize, map } from 'rxjs/operators';
import { BaseService } from '@core/base.service';
import { AuthService } from '@core/auth.service';
import { ToasterService } from '@core/toaster.service';
import { Constants } from '@shared/services/constants.service';
import { environment } from '@env/environment';
var AuthInterceptor = /** @class */ (function (_super) {
    tslib_1.__extends(AuthInterceptor, _super);
    function AuthInterceptor(router, toaster, http, authService) {
        var _this = _super.call(this, router, toaster) || this;
        _this.router = router;
        _this.toaster = toaster;
        _this.http = http;
        _this.authService = authService;
        _this.refreshTokenInProgress = false;
        _this.refreshTokenSubject = new BehaviorSubject(null);
        return _this;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        req = this.addAuthenticationToken(req);
        return next.handle(req).pipe(catchError(function (err) {
            if (err && (err.status === 401 || err.status === 422 || err.status === 404)) {
                if ((req.url.indexOf('sign_in.json') !== -1) ||
                    (req.url.indexOf('users.json') !== -1) ||
                    (req.url.indexOf('token_confirmation.json') !== -1)) {
                    return _this.loginErrorHndlr(err);
                }
                else {
                    if (_this.refreshTokenInProgress) {
                        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                        // which means the new token is ready and we can retry the request again
                        return _this.refreshTokenSubject.pipe(filter(function (result) { return result !== null; }), take(1), switchMap(function () { return next.handle(_this.addAuthenticationToken(req)); }));
                    }
                    else {
                        _this.refreshTokenInProgress = true;
                        // Set the refreshTokenSubject to null so that subsequent API calls will wait until
                        // the new token has been retrieved
                        _this.refreshTokenSubject.next(null);
                        var params = {
                            uuid: _this.getUuid(),
                            refresh_token: _this.getRefreshToken()
                        };
                        return _this.http.put(_this.baseUrl + Constants.apiEndPoints.refreshToken, params).pipe(switchMap(function (data) {
                            _this.setAuthToken(data.user.access_token);
                            _this.setRefreshToken(data.user.refresh_token);
                            _this.setUuid(data.user.user_id);
                            _this.refreshTokenSubject.next(true);
                            return next.handle(_this.addAuthenticationToken(req));
                        }), finalize(function () { return _this.refreshTokenInProgress = false; }));
                    }
                }
            }
            else {
                return _this.genericError(err);
            }
        }), map(function (response) {
            if (req.method.toLocaleLowerCase() === 'put') {
                if (req.url.indexOf('/features') !== -1) {
                    if (response.body) {
                        _this.genericSuccess(response);
                    }
                }
            }
            return response;
        }));
    };
    AuthInterceptor.prototype.addAuthenticationToken = function (request) {
        if (!this.getAuthToken()) {
            return request;
        }
        if (request.url.includes('verticals_with_subverticals')) {
            return request.clone({
                headers: request.headers.set('authtoken', environment.AUTHTOKEN_EXTERNAL)
            });
        }
        else {
            return request.clone({
                headers: request.headers.set('authtoken', this.getAuthToken())
            });
        }
    };
    return AuthInterceptor;
}(BaseService));
export { AuthInterceptor };
