import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var ToasterService = /** @class */ (function () {
    function ToasterService(toastr) {
        this.toastr = toastr;
        this.toasterOptions = {
            timeOut: 2000,
            positionClass: 'toast-bottom-center'
        };
    }
    ToasterService.prototype.success = function (title, body) {
        this.toastr.success(title, body, this.toasterOptions);
    };
    ToasterService.prototype.error = function (title, body) {
        this.toastr.error(title, body, this.toasterOptions);
    };
    ToasterService.prototype.info = function (title, body) {
        this.toastr.info(title, body, this.toasterOptions);
    };
    ToasterService.prototype.warning = function (title, body) {
        this.toastr.warning(title, body, this.toasterOptions);
    };
    ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(i0.ɵɵinject(i1.ToastrService)); }, token: ToasterService, providedIn: "root" });
    return ToasterService;
}());
export { ToasterService };
