import { WindowRefService } from '@core/window-ref.service';
import * as i0 from "@angular/core";
import * as i1 from "./window-ref.service";
/**
 * Now cookie management service
 */
var CookieService = /** @class */ (function () {
    function CookieService(window) {
        this.window = window;
    }
    CookieService.prototype.setCookie = function (name, value, expiryDate, path) {
        if (path === void 0) { path = ''; }
        this.window.nativeDocument.cookie = name + "=" + value + ";expires=" + expiryDate + ";path=" + path;
    };
    CookieService.prototype.getCookie = function (name) {
        var regexp = new RegExp(name + '=([^;]+)');
        return regexp.exec(this.window.nativeDocument.cookie);
    };
    CookieService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CookieService_Factory() { return new CookieService(i0.ɵɵinject(i1.WindowRefService)); }, token: CookieService, providedIn: "root" });
    return CookieService;
}());
export { CookieService };
